<template>
  <div class="page_container">
    <section class="row center">
      <div class="pt-35 pb-5 w600 row space-between">
        <h1 class="my-0">View your license</h1>
        <router-link to="/licenses" class="row align-end gap-10 mb-2">
          <s-icon color="white" height="20">chevron-left</s-icon>
          <div class="my-0">Back</div>
        </router-link>
      </div>
    </section>
    <hr class="mt-15" />
    <section class="center py-50 col">
      <div class="w600 weight-300">Software license details</div>
    </section>

    <section class="w600 mx-auto mb-20">
      <div class="grid">
        <div class="label">License Number</div>
        <div class="item">{{ license.licenseNumber }}</div>
        <div class="label">Product</div>
        <div class="item">{{ sentense(getName(license.product)) }}</div>
        <div class="label">Price</div>
        <div class="item">{{ formatCurrency(license.price) }}</div>
        <div class="label">Expires</div>
        <div class="item">{{ format(license.expires) }}</div>
        <div class="label">Acquisition Date</div>
        <div class="item">{{ format(license.createdAt) }}</div>

        <div class="label">Discounted Price</div>
        <div class="item">{{ formatCurrency( license.discountedPrice) }}</div>

        <div class="label">Discount Expiry</div>
        <div class="item">{{ license.priceDiscountTimeLimit }}</div>

        <div class="label">License Parameters</div>
        <div class="item">{{ license.licenseParameters }}</div>

        <div class="label">Active</div>
        <div class="item">{{ license.isActive }}</div>
        <div class="label">Expired</div>
        <div class="item">{{ license.isExpired }}</div>
        <div class="label">Notes</div>
        <div class="item">{{ license.note }}</div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
export default {
  data() {
    return {};
  },
  methods: {
     formatCurrency(x) {
      x = Number(x).toFixed(2);
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
       return "$ " + parts.join(".");
    },
    format(val) {
      return dayjs(val).format("DD MMM YY");
    },
    sentense(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
     getName(id) {
      return this.products.find((el) => el.id == id).name;
    },
  },
  computed: {
    ...mapState("licenses", ["license"]),
     ...mapState("products", ["products"]),
  },
  watch: {},
  mounted() {
    this.$store.dispatch("products/getProducts");
  },
};
</script>

<style lang="less" scoped>
.label {
  background-color: rgba(0, 0, 0, 0.35);
  padding: 8px;
  border-bottom: 1px solid #42515e;
}

.item {
  display: flex;
  align-items: center;
  padding-left: 8px;
  border-bottom: 1px solid #42515e;
  border-right: 1px solid #42515e;
}

.grid {
  display: grid;
  grid-template-columns: 240px 1fr;
  grid-column-gap: 2px;
  width: 100%;
  transition: 0.2s all ease;
  border-top: 1px solid #42515e;
}
</style>